import axios from 'axios';

import { convertTimeToMinutes } from '@/utility/dateHelper';

import { showToast } from '@/components/common/ToastContainer';
import {
  removeAllToasts,
  showSuccessToast
} from '@/components/common/ToastContainer/ToastContainer';

import getConstants from '../constants';

export const populateFormData = ({ dataObject }) => {
  const formData = new FormData();
  try {
    Object.keys(dataObject).forEach((key) => {
      if (Array.isArray(dataObject[key])) {
        formData.append(key, JSON.stringify(dataObject[key]));
      } else formData.append(key, dataObject[key]);
    });

    return formData;
  } catch (e) {
    console.error(e);
    return formData;
  }
};

export const getTimeLeftLogic = (minutes, seconds) => {
  if (minutes == 'Infinity') {
    return 'Calculating time left...';
  }
  if (minutes > 0) {
    return `About ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} left`;
  }
  if (seconds > 10 && seconds < 60) {
    return `Less than 1 minute left`;
  }
  if (seconds > 0 && seconds < 10) {
    return `Finishing...`;
  }
};

export const convertCourseTimeFromToMinAndHours = (timeInSecs) => {
  try {
    const hours = Math.floor(timeInSecs / 3600);
    const minutes = Math.floor((timeInSecs - hours * 3600) / 60);
    const remainingSecs = timeInSecs - hours * 3600 - minutes * 60;
    return {
      hours: hours > 0 ? hours : null,
      minutes: minutes > 0 ? minutes : null,
      secs: remainingSecs
    };
  } catch (e) {
    console.error(e);
  }
};

// return 10h 30m 20s format
export const getFormattedTimeStrFromMs = (timeInMs) => {
  try {
    const { hours, minutes, secs } = convertCourseTimeFromToMinAndHours(
      timeInMs / 1000
    );
    let formattedStr = '';

    if (hours) formattedStr += `${Math.trunc(hours)}h`;
    if (minutes) formattedStr += ` ${Math.trunc(minutes)}m`;
    if (secs) formattedStr += ` ${Math.trunc(secs)}s`;

    return formattedStr.trim();
  } catch (e) {
    console.error(e);
  }
};

export const getLibraryPageUrl = (params) => {
  const folderId = params?.folderId;
  const isEditing = params?.isEditing;

  const { LIBRARY_PAGE } = getConstants();

  if (folderId && isEditing)
    return `${LIBRARY_PAGE.link}/${folderId}/edit`;

  if (folderId) return `${LIBRARY_PAGE.link}/${folderId}`;

  return LIBRARY_PAGE.link;
};

export const getThumbnailProps = (thumbnail) => {
  return {
    alt: 'thumbnail',
    mobileImgProps: {
      src: thumbnail,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    desktopImgProps: {
      src: thumbnail,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };
};

export function isPdfFile(link) {
  return link?.split('.').includes('pdf');
}

export function getFileExtension(link) {
  return link?.split('.').pop().toUpperCase();
}

export function getFileFormat(link) {
  return link?.split('/').pop();
}

export function formatSize(bytes, decimals = 0) {
  if (!+bytes) return '';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const downloadFile = (t, filePath, fileName = 'download') => {
  try {
    showToast({
      text: t('preparing-file-for-download'),
      dismissOnClick: true
    });
    const element = document.createElement('a');
    element.href = filePath;
    element.download = fileName;
    element.target = '_blank';
    element.rel = 'noopener noreferrer';
    document.body.appendChild(element);
    element.click();
    removeAllToasts();
    showToast({ text: t('downloaded-successfully'), type: 'success' });
    document.body.removeChild(element);
  } catch (error) {
    showToast({ text: error, type: 'error' });
  }
};

export function openDownloadLinkInNewTab(link, filename = 'download') {
  // Create a new anchor element
  const anchor = document.createElement('a');
  anchor.style.display = 'none';

  // Set the href attribute to the video link
  anchor.href = link;
  anchor.target = '_blank';

  // Set the download attribute to suggest a filename for the downloaded file
  anchor.download = filename;

  // Add the anchor element to the document
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor element
  anchor.click();

  // Remove the anchor element from the document
  setTimeout(() => {
    document.body.removeChild(anchor);
  }, 200);
}

export const fileDownload = (data, filename, mime, bom) => {
  var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  var blob = new Blob(blobData, {
    type: mime || 'application/octet-stream'
  });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

// export function download(url, filename = 'download') {
//   axios
//     .get(url, {
//       responseType: 'blob'
//     })
//     .then((res) => {
//       fileDownload(res.data, filename);
//     });
// }

export function download(t, url, filename = 'download') {
  try {
    showToast({
      text: t('preparing-file-for-download'),
      dismissOnClick: true
    });
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, filename);
        removeAllToasts();
        showSuccessToast(t('downloaded-successfully'));
      });
  } catch (e) {
    showToast({ text: e, type: 'error' });
  }
}

export const copyTextToClipboard = ({ text }) => {
  try {
    //if the browser support the navigator clipboard api
    if (
      navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText
    ) {
      navigator.clipboard.writeText(text);
      return;
    }

    //else the obvious method
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  } catch (e) {
    console.error('Copy to clipboard did not work!', e);
  }
};

export const getOneOnOneSessionEditUrl = ({
  oneOnOneSessionId,
  activeCommunityId
}) => {
  return `/portal/products/1on1session/edit/${oneOnOneSessionId}?activeCommunityId=${activeCommunityId}`;
};

export const calculateNextTimeSlot = (lastTo) => {
  let newFrom = '00:00',
    newTo = '01:00'; // Default values

  if (lastTo) {
    const [hours, minutes] = lastTo.split(':').map(Number);
    const newFromTime = new Date(0, 0, 0, hours, minutes);
    newFromTime.setHours(newFromTime.getHours() + 1); // Add 1 hour to the last "to" time

    const newToTime = new Date(newFromTime);
    newToTime.setHours(newToTime.getHours() + 1); // Set "to" time 1 hour after "from"

    newFrom = `${newFromTime
      .getHours()
      .toString()
      .padStart(2, '0')}:${newFromTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    newTo = `${newToTime
      .getHours()
      .toString()
      .padStart(2, '0')}:${newToTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }

  return { from: newFrom, to: newTo };
};

export const getIsEndTimeBiggerThanStartTIme = (startTime, endTIme) => {
  // Change time to numbers (14:30 - 1430)
  const startHoursMinutes = parseInt(startTime.replace(':', ''), 10);
  const endHoursMinutes = parseInt(endTIme.replace(':', ''), 10);

  if (endHoursMinutes <= startHoursMinutes) {
    return false;
  }
  return true;
};

// Helper function for time overlap checking
export const isOverlap = (intervals, intervalIndex, time1, time2) => {
  for (let i = 0; i < intervals.length; i++) {
    if (i === intervalIndex) continue; // Skip the current interval

    const interval = intervals[i];
    if (
      (time1 < interval.to && time2 > interval.from) ||
      (time2 > interval.from && time1 < interval.to)
    ) {
      return true; // Overlap detected
    }
  }
  return false; // No overlap
};

export function convertToKebabCase(text) {
  return text.toLowerCase().replace(/\s+/g, '-');
}

export const checkIsIntervalLowerThanDuration = (
  startTime,
  endTime,
  duration
) => {
  const startTimeInMinutes = convertTimeToMinutes(startTime);
  const endTimeInMinutes = convertTimeToMinutes(endTime);

  // Calculate the available duration in minutes
  const availableDuration = endTimeInMinutes - startTimeInMinutes;

  // Check if selectedDuration can acommadate availableDuration
  return duration >= availableDuration;
};

export const calculateCompletedProgressPercentage = (
  uploadingItems,
  totalFileSize
) => {
  const sizeUploaded = (Object.keys(uploadingItems) ?? []).reduce(
    (total, item) => {
      const fileSize = uploadingItems[item]?.fileSize ?? 0;
      const totalProgress = uploadingItems[item]?.totalProgress ?? 0;
      const szUploaded = (totalProgress / 100) * fileSize;
      return total + szUploaded;
    },
    0
  );
  const percent = (sizeUploaded / totalFileSize) * 100;
  return percent;
};
