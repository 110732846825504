import React from 'react';

import NextImage from '@/components/common/NextImage';

const LocalBlurredBackground = ({ bannerImg, alt }) => {
  const imageProps = {
    alt: alt ?? bannerImg,
    className: 'w-full h-full blur-3xl opacity-20',
    mobileImgProps: {
      src: bannerImg,
      quality: 1,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };

  if (!bannerImg) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-[-1] aspect-[16/9] h-full w-full">
      <NextImage {...imageProps} />
    </div>
  );
};

export default LocalBlurredBackground;
