import classNames from 'classnames';
import React, { useState } from 'react';

import { t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';

import FileFolderItem from './FileFolderItem';

const SectionFolderItem = ({
  folderItem,
  defaultExpanded,
  hasAccess,
  // folderId,
  // activeCommunityId,
  ctaOnClick,
  communitySlug,
  productSlug
}) => {
  const { title, items } = folderItem;

  const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="p-24">
      <div>
        {/* Section */}
        <div
          className={classNames('flex justify-between', {
            'cursor-pointer': items.length
          })}
          onClick={items.length ? toggleExpanded : null}>
          {title !== '' ? (
            <div
              className={classNames(
                'space-y-1',
                !items.length && 'hover:cursor-default'
              )}>
              <h3 className="text-heading-xs font-semibold text-npl-text-icon-on-light-surface-primary">
                {title}
              </h3>
              <h3 className="flex items-center justify-start text-label-md text-npl-text-icon-on-light-surface-tertiary">
                {items.length} {t('items')}
              </h3>
            </div>
          ) : (
            <h3 className="flex items-center justify-center text-label-md text-npl-text-icon-on-light-surface-tertiary">
              {items.length} {t('items')}
            </h3>
          )}
          {!!items.length && (
            <div
              className={classNames(
                'duration-250 flex h-40 w-40 items-center justify-center transition-all',
                {
                  'rotate-[-180deg]': isExpanded
                }
              )}>
              <Icon name="chevron-down" />
            </div>
          )}
        </div>

        {!!items.length && (
          <div
            className={classNames('transition-all duration-300', {
              'overflow-visible': isExpanded,
              'max-h-0 overflow-hidden': !isExpanded
            })}>
            <div className="mt-5 flex flex-col space-y-16">
              {Array.isArray(items) &&
                items.map((item, key) => {
                  return (
                    <FileFolderItem
                      // activeCommunityId={activeCommunityId}
                      // folderId={folderId}
                      ctaOnClick={ctaOnClick}
                      hasAccess={hasAccess}
                      key={key}
                      item={item}
                      communitySlug={communitySlug}
                      productSlug={productSlug}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionFolderItem;
