import React from 'react';

import NextImage from '@/components/common/NextImage';

type folderItems = {
  _id: string;
  description: string;
  format: string;
  index: number;
  size: string;
  status: string;
  thumbnail: string;
  title: string;
  type: string;
  items?: folderItems[];
};

type HeroBannerProps = {
  folderItems: folderItems[];
  loading: boolean;
  thumbnail: string;
};

const HeroBanner = ({ thumbnail }: HeroBannerProps) => {
  return (
    <div className="aspect-h-9 aspect-w-16 relative h-full w-full">
      <NextImage
        mobileImgProps={{
          src: thumbnail,
          layout: 'fill',
          objectFit: 'cover',
          priority: true
        }}
        className="rounded-3xl"
        alt="resource-image"
      />
    </div>
  );
};

export default HeroBanner;
