import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Modal from '@/components/common/Modal';
import NPLButton from '@/components/npl/NPLButton';

export const ReadMoreModal = ({
  className,
  children,
  maxHeight,
  buttonStyling = '',
  ctaOnClick,
  ctaText,
  label = t('see-more2')
}) => {
  const contentContainer = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { isGtEqLg } = useWindowWidthContext();

  useEffect(() => {
    if (contentContainer?.current) {
      setIsOverflowing(
        contentContainer?.current?.scrollHeight >
          contentContainer?.current?.clientHeight ||
          contentContainer?.current?.scrollWidth >
            contentContainer?.current?.clientWidth
      );
    }
  }, [contentContainer, children, isGtEqLg]);

  return (
    <div
      className={classnames([
        'overflow-hidden overflow-ellipsis ',
        className
      ])}>
      <div
        ref={contentContainer}
        style={{
          maxHeight: maxHeight,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word'
        }}>
        {children}
      </div>
      {isOverflowing && !isExpanded && (
        <button
          className={`bg-transparent font-semibold text-neutral-10 outline-none ${buttonStyling}`}
          onClick={() => setIsExpanded(true)}>
          {label}
        </button>
      )}
      <Modal
        customCloseIconClass=""
        containerBackgroundTransparent=""
        customEyeBrowClass=""
        chin={
          <div className="px-20 py-3 lg:px-32">
            <NPLButton
              leadIcon=""
              buttonText={ctaText}
              onClick={() => {
                ctaOnClick();
                setIsExpanded(false);
              }}
              size="lg"
              stretch={true}
              hierarchy="accent_primary"
            />
          </div>
        }
        eyebrow={
          <div className="border-b border-npl-neutral-light-solid-4">
            <h1 className="py-16 pl-20 text-heading-xs font-semibold text-npl-text-icon-on-light-surface-primary lg:pl-32">
              {t('description')}
            </h1>
          </div>
        }
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        size="md">
        <div className="whitespace-break-spaces break-words">
          {children}
        </div>
      </Modal>
    </div>
  );
};
